import { useEffect, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";
import { deleteTalentFromList, incrementListAddHistory, updateList, useExchangeRate, useFreshCountries, useFreshLists, useFreshTags, useFreshTalent, useListById, UserContext } from "../services/firebase";
import grailUtil from "../services/grailUtil";
import { List, TalFilter, Talent, SelectedTalent, ListAddCountsByMonth } from "../shared/types";

//
import Layout from "../components/Layout";
import TalentFilter, { defaultFilter } from "../components/TalentFilter";

//
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { del } from "idb-keyval";

export default function EditList() {
    const user = useContext(UserContext);
    const { listId } = useParams<{ listId: string }>();

    const talentQuery = useFreshTalent(false, false); // don't allow managers to add hidden/deleted talent to lists, since they'll be seen by bookers
    const rawTalent = talentQuery.flatData;

    const countriesQuery = useFreshCountries();
    const countryList = countriesQuery.flatData.filter((country) => country.liveOnAirtable).map((country) => country.label).sort();
    const tagsQuery = useFreshTags();
    const tagList = tagsQuery.flatData.map((tagDoc) => tagDoc.tag).sort();

    const listsQuery = useFreshLists({});
    const lists = [{ id: "", name: "All", active: true }, ...listsQuery.flatData];

    const poundsToDollars = useExchangeRate();
    const [state, dispatch] = useReducer(listStateReducer, { name: '', talent: [], selectedTalent: {}, creatorRequest: false, useCustomPrices: false, filter: { ...defaultFilter, showCampaignTypeSelect: true } });
    const { name, talent, selectedTalent, filter, useCustomPrices, creatorRequest, listUid, originalList } = state;

    // EFFECT: calculateTalentWithPrices
    useEffect(() => {
        // console.log("EFFECT: calculateTalentWithPrices");
        const talentWithPrice = calculateTalentWithPrices(rawTalent, poundsToDollars, filter);
        dispatch({ type: 'setStatePartial', payload: { talent: talentWithPrice } });
    }, [rawTalent, poundsToDollars, filter]);

    // EFFECT: List
    // useEffect(() => {
    //     if (listId) {
    //         getListById(listId).then((list) => dispatch({ type: 'setListFromDatabase', list }))
    //     }
    // }, [listId]);
    const list = useListById(listId || '');
    useEffect(() => {
        if (list?.id) {
            dispatch({ type: 'setListFromDatabase', list })
        }
    }, [list]);

    async function handleSave() {
        const deletedTalentIds = Object.keys(state.originalList?.talent || {}).filter((talId) => !Object.keys(selectedTalent).includes(talId));

        if (listId) {
            if (deletedTalentIds.length) {
                const deletedTalentPromises = [];

                for (const talId of deletedTalentIds) {
                    deletedTalentPromises.push(deleteTalentFromList(listId, talId));
                }

                await Promise.all(deletedTalentPromises);
            }

            const listUpdates: Partial<List> = {
                id: listId,
                creatorRequest: !!creatorRequest,
                name,
                platform: filter.campaignPlatform,
                ...(useCustomPrices && {
                    campaignType: filter.campaignType,
                    currency: filter.currency,
                }),
                talent: selectedTalent,
                useCustomPrices
            };

            const res = await updateList(listUpdates);

            if (res.success) {
                dispatch({ type: 'setStatePartial', payload: { showAlert: 'success' } })
            } else {
                console.log(`Error saving list`, listUpdates);
            }

            try {
                const originalTalentIds = Object.keys(originalList?.talent || {});
                const newTalentIds = Object.keys(selectedTalent);
                const netNewIds = newTalentIds.filter(id => !originalTalentIds.includes(id));
                console.log(`TJN netNewIds`, netNewIds);

                if (netNewIds.length) {
                    const listAddKey: keyof ListAddCountsByMonth = creatorRequest ? "creatorRequestLists" : "managerLists";

                    incrementListAddHistory({ talentIds: netNewIds, managerId: user?.id || '', listAddKey });
                    //console.log(`TJN netNewIds`, netNewIds);
                }
            } catch (e) {
                console.log(e);
            }


            console.log(`res`, res);
        }
    }

    function handleCustomPriceCheckbox(isChecked: boolean) {
        dispatch({ type: 'setStatePartial', payload: { useCustomPrices: isChecked } });
        dispatch({ type: 'updateFilter', filterPartial: { lockCurrencyFromBooker: isChecked, lockCampaignType: isChecked } });
    }

    function handleCreatorRequestCheckbox(isChecked: boolean) {
        dispatch({ type: 'setStatePartial', payload: { creatorRequest: isChecked } });
        dispatch({ type: 'updateFilter', filterPartial: { lockCampaignPlatform: isChecked } })
    }

    return (
        <Layout gray={true}>
            <div className="row-fluid p-0">
                <div className="col-md-8 p-100">
                    {name ? (
                        <TalentFilter
                            talent={talent}
                            selectedTalent={selectedTalent}
                            updateFilter={(filterPartial) => dispatch({ type: 'updateFilter', filterPartial })}
                            filter={filter}
                            countryList={countryList}
                            tagList={tagList}
                            lists={lists}
                            handleAdd={(id, selectedTalentItem) => dispatch({ type: 'addTalent', id, selectedTalentItem, addedBy: user!.id! })}
                            handleRemove={(id) => dispatch({ type: 'removeTalent', id })}
                        />
                    ) : <CircularProgress size="1.5rem" />}

                </div>
                <div className="col-md-4 bg-white p-100">
                    <Alert onClose={() => dispatch({ type: 'setStatePartial', payload: { showAlert: undefined } })} sx={{ marginBottom: '1rem', display: state.showAlert ? 'flex' : 'none' }}>
                        Your changes have been saved!
                    </Alert>

                    <button onClick={handleSave} className="btn btn-primary">Save</button>
                    <div className="form-group mt-100">
                        <label className="required">List Name</label>
                        <input name="name" id="name" value={name} onChange={(e) => dispatch({ type: 'setStatePartial', payload: { name: e.target.value } })} />
                    </div>

                    <div className="form-group mt-100">
                        <div>
                            <label htmlFor="customPrices">Use Custom Prices?</label>
                        </div>
                        <div className="flex">
                            <input className="mr-75" type="checkbox" name="customPrices" id="customPrices"
                                checked={useCustomPrices}
                                // onChange={(e) => dispatch({ type: 'setStatePartial', payload: { useCustomPrices: e.target.checked } })}
                                onChange={(e) => handleCustomPriceCheckbox(e.target.checked)}
                                disabled={!!Object.keys(selectedTalent).length || user?.id !== listUid}
                            />
                            <label htmlFor="customPrices"><span className="font-weight-light">Use Custom Prices</span></label>
                        </div>
                        <div className="font-12">Display custom prices to list viewers. Note: This will lock currency and campaign type <em>for bookers using this list</em>. Cannot be changed if there are already creators on the list.</div>
                    </div>

                    {user?.roles?.admin &&
                        <div className="form-group mt-100">
                            <div>
                                <label htmlFor="creatorRequest">Active Creator Request?</label>
                            </div>
                            <div className="flex">
                                <input type="checkbox" className="mr-75" name="creatorRequest" id="creatorRequest"
                                    checked={creatorRequest} onChange={(e) => handleCreatorRequestCheckbox(e.target.checked)} />
                                <label htmlFor="creatorRequest"><span className="font-weight-light">List is Editable by all managers</span></label>
                            </div>
                            <div className="font-12">Allow other managers to edit this list. This will lock campaign platform for <em>other managers</em>. Platform cannot be changed once creators are already on the list.</div>
                        </div>
                    }

                    <div className="form-group mt-100">
                        <label className="required">Platform</label>
                        <div className="font-12 mb-50">Locked once creators are added, or if "Active Creator Request" is checked.</div>
                        <div className="flex align-items-center">
                            {filter.campaignPlatform === "TikTok" ? (<><img className="true-color-icon mr-75" src="/icon-tiktok.svg" alt="TikTok" /> TikTok</>) : null}
                            {filter.campaignPlatform === "Instagram" ? (<><img className="true-color-icon mr-75" src="/icon-instagram.svg" alt="Instagram" /> Instagram</>) : null}
                            {filter.campaignPlatform === "YouTube" ? (<><img className="true-color-icon mr-75" src="/icon-youtube.svg" alt="YouTube" /> YouTube</>) : null}
                        </div>
                    </div>

                    <div className="form-group mt-100">
                        <label className="required">Talent
                            {
                                (useCustomPrices &&
                                    <>- {grailUtil.getCurrencySymbol(filter.currency)}{sumCustomTalentPrices(selectedTalent).toLocaleString()}</>)
                                || (filter.campaignType === 'Song' &&
                                    <>- {grailUtil.getCurrencySymbol(filter.currency)}{sumDefaultTalentPrices(Object.keys(selectedTalent).map((talId) => talent.find((tal) => tal.id === talId))).toLocaleString()}</>)
                                || null
                            }
                        </label>
                    </div>

                    {Object.keys(selectedTalent).map((talId) => {
                        const tal = talent.find((tal) => tal.id === talId);
                        return (
                            <div className="TalentMini" key={talId}>
                                <div className="flex flex-1-1-auto">
                                    <div className="TalentImg" style={{ backgroundImage: `url(${tal?.imgUrl ? tal.imgUrl : 'talent-default.png'})` }}></div>
                                    <div className="flex-1-1-auto">
                                        {/* <div className="mb-25"><strong>@{tal?.tiktokUser}</strong></div> */}
                                        <div className="mb-25">
                                            <strong>
                                                {/* @{tal?.tiktokUser || tal?.instagramUser || tal?.youtubeUser}</strong> */}
                                                {/* - {grailUtil.getCurrencySymbol(currency)} */}
                                                {/* {tal?.price.toLocaleString()} */}


                                                {filter.campaignPlatform === "TikTok" && <>
                                                    <a className="silent-link inline-flex align-items-center" href={`https://tiktok.com/@${tal?.tiktokUser?.toLowerCase()}`} target="_blank" rel="noreferrer">
                                                        <img className="true-color-icon icon-16 mr-50" src="/icon-tiktok.svg" alt="TikTok" />
                                                        @{tal?.tiktokUser}
                                                    </a>
                                                </>}
                                                {filter.campaignPlatform === "Instagram" && <>
                                                    <a className="silent-link inline-flex align-items-center" href={`https://instagram.com/${tal?.instagramUser}`} target="_blank" rel="noreferrer">
                                                        <img className="true-color-icon icon-16 mr-50" src="/icon-instagram.svg" alt="Instagram" />
                                                        @{tal?.instagramUser}
                                                    </a>


                                                </>}
                                                {filter.campaignPlatform === "YouTube" && <>
                                                    <a className="silent-link inline-flex align-items-center" href={"https://www.youtube.com/@" + tal?.youtubeUser} target="_blank" rel="noreferrer">
                                                        <img className="true-color-icon icon-16 mr-50" src="/icon-youtube.svg" alt="YouTube" />
                                                        {tal?.youtubeUser}
                                                    </a>
                                                </>
                                                }
                                            </strong>

                                            {(useCustomPrices && <>
                                                &nbsp;- {grailUtil.getCurrencySymbol(filter.currency)}{selectedTalent[talId].price}
                                            </>)
                                                || (filter.campaignType === 'Song' && <>
                                                    &nbsp;- {grailUtil.getCurrencySymbol(filter.currency)}{talent.find((tal) => tal.id === talId)?.defaultPrice} (Default Price)
                                                </>)
                                                || null}

                                            <div className="mt-50">
                                                <div className="text-uppercase font-12 font-gray-6 mb-25">Internal Notes:</div>
                                                <textarea rows={2} style={{ resize: 'vertical', width: '100%' }} value={selectedTalent[talId].notes} onChange={(e) => dispatch({ type: 'updateTalentNotes', id: talId, notes: e.target.value })} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="ml-25">

                                    {  // Only show remove button for admins, list owners, and managers of that creator
                                        user?.roles?.admin || user?.id === listUid || (user?.id && tal?.managerIds?.includes(user.id))
                                            ? (
                                                <IconButton onClick={() => dispatch({ type: 'removeTalent', id: talId })} aria-label="remove">
                                                    <CloseIcon />
                                                </IconButton>
                                            ) : null
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </Layout>
    )
}

// type SelectedTalent = { [key: string]: number };
// type SelectedTalent = {}

interface ListState {
    creatorRequest?: boolean,
    name: string,
    filter: TalFilter,
    selectedTalent: SelectedTalent,
    talent: Talent[],
    // list?: List,
    showAlert?: 'success' | 'error',
    useCustomPrices?: boolean,
    listUid?: string,
    originalList?: List,
    // totalTiktokFollowers: number, // depends on selectedTalent
    // totalInstagramFollowers: number, // depends on selectedTalent,
    // totalPrice: number, // depends on selectedTalent
}

type ListAction =
    { type: 'setStatePartial', payload: Partial<ListState> } |
    { type: 'setListFromDatabase', list: List } |
    // { type: 'addTalent', id: string, bidPrice: number, isRec?: boolean, useCustomPrices?: boolean, addedBy: string, notes?: string } |
    { type: 'addTalent', id: string, selectedTalentItem: SelectedTalent["id"], addedBy: string } |
    { type: 'updateTalentNotes', id: string, notes: string } |
    { type: 'removeTalent', id: string } |
    { type: 'updateFilter', filterPartial: Partial<TalFilter> };

function listStateReducer(state: ListState, action: ListAction): ListState {
    switch (action.type) {
        case 'setStatePartial': {
            return { ...state, ...action.payload } as ListState;
        }
        case 'updateFilter': {
            console.log("newFilter", {
                ...state.filter,
                ...action.filterPartial
            });
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filterPartial
                } as TalFilter
            };
        }
        case 'setListFromDatabase': {
            const list = action.list;
            let selectedTalent: SelectedTalent;
            let useCustomPrices = false;

            // if (list.talent) {
            // PERM: new format
            selectedTalent = list.talent || {};

            // if any talent has a price on it, useCustomPrices
            if (Object.values(list.talent || {}).find((tal) => tal.price) || list.useCustomPrices) {
                useCustomPrices = true;
            }
            // }
            // else {
            //     // TEMP: old format
            //     selectedTalent = (list.talentIds || []).reduce((acc, cv) => Object.assign(acc, { [cv]: { price: null } }), {});
            // }

            return {
                ...state,
                name: list.name,
                creatorRequest: list.creatorRequest,
                selectedTalent,
                useCustomPrices,
                filter: {
                    ...state.filter,
                    campaignPlatform: list.platform || "TikTok",
                    ...(useCustomPrices && {
                        lockCampaignPlatform: true,
                        lockCampaignType: true,
                        lockCurrencyFromBooker: true,
                        campaignType: list.campaignType
                    })
                },
                listUid: list.uid,
                originalList: list,
            };
        }
        case 'addTalent': {
            const { id, selectedTalentItem, addedBy } = action;

            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            const newTalentObj = Object.assign({}, state.selectedTalent[id], { price: state.useCustomPrices ? selectedTalentItem.price : null, addedBy, notes: selectedTalentItem.notes || "" });
            newSelectedTalent[id] = newTalentObj;

            return { ...state, selectedTalent: newSelectedTalent };
        }
        case 'updateTalentNotes': {
            const { id, notes } = action;
            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            newSelectedTalent[id].notes = notes;
            return { ...state, selectedTalent: newSelectedTalent }
        }
        case 'removeTalent': {
            const { id } = action;
            const newSelectedTalent: SelectedTalent = JSON.parse(JSON.stringify(state.selectedTalent));
            delete newSelectedTalent[id];
            return { ...state, selectedTalent: newSelectedTalent };
        }
    }
}

function calculateTalentWithPrices(talent: Talent[], poundsToDollars: number, filter: TalFilter) {
    //console.log("FUNCTION: recalculateTalentPrices", talent.length, poundsToDollars);

    let talentWithPrice = talent.map((tal: Talent) => {
        const localPrices = grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency);
        const defaultPrice = grailUtil.getDefaultGuidePrice(localPrices || {}, filter.campaignPlatform, filter.campaignType || "Song");

        return {
            ...tal,
            defaultPrice: defaultPrice,
            localPrices: grailUtil.getLocalPrices(tal, poundsToDollars, filter.currency)
        } as Talent
    });

    return talentWithPrice;
}

function sumCustomTalentPrices(selectedTalent: SelectedTalent) {
    let total = 0;
    for (const tal of Object.values(selectedTalent)) {
        total += tal.price || 0;
    }
    return total;
}

function sumDefaultTalentPrices(talent: (Talent | undefined)[]) {
    let total = 0;
    for (const tal of talent) {
        if (tal) {
            total += tal.defaultPrice || 0;
        }
    }
    return total;
}
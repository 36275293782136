import { useMemo, useContext, } from 'react';
import { UserContext } from '../services/firebase';
import grailUtil from '../services/grailUtil';
import { Talent, TalFilter, List, Platform, Prices, SelectedTalent, CampaignType } from '../shared/types';

import SearchIcon from '@mui/icons-material/Search';
import './TalentFilter.scss';
import ChipSelect from './ChipSelect';
import ChipRange from './ChipRange';
import ChipRadioGroup from './ChipRadioGroup';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// NEW
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

//
import CurrencySwitch from './CurrencySwitch';
import TalentList from './TalentList';
import DataLoading from './DataLoading';
import { Tooltip } from '@mui/material';

export const defaultFilter: TalFilter = {
    campaignPlatform: undefined, // Formerly: "TikTok"
    cities: [],
    contentTags: [],
    countries: [],
    search: '',
    listId: '',
    sortBy: 'tt-views-desc',
    ttFollowersRange: {
        lower: 0,
        upper: 5000000,
    },
    priceRange: {
        lower: 0,
        upper: 10000,
    },
    tagFilterLogic: 'OR',
    campaignType: "Song",
    currency: 'USD',
    managerMode: false,
    adminMode: false,
    filterIndex: 1,
    lockCampaignPlatform: false,
    lockTalentCards: false,
};

// START SORT OPTIONS STUFF
type SortOption = {
    name: string,
    platform: string,
    function: string,
    value: string,
    direction: "desc" | "asc",
}

const sortOptionsBase: Partial<SortOption>[] = [
    { platform: "ig", name: 'Followers', function: 'followers' },
    { platform: "ig", name: 'Song Price', function: 'songPriceInstagram' },
    // { platform: "ig", name: 'Post Price', function: 'brandPriceInstagramPost' },
    { platform: "ig", name: 'Reel Price', function: 'brandPriceInstagramReel' },
    // { platform: "ig", name: 'Story Price', function: 'brandPriceInstagramStory' },
    { platform: "tt", name: 'Avg Views', function: 'views' },
    { platform: "tt", name: 'Price', function: 'brandPriceTikTok' },
    { platform: "tt", name: 'Price', function: 'songPriceTikTok' },
    { platform: "tt", name: 'Followers', function: 'followers' },
    { platform: "tt", name: 'Engagement', function: 'engagement' },
    { platform: "yt", name: 'Subscribers', function: 'followers' },
    { platform: "yt", name: 'Song Price', function: 'songPriceYouTube' },
    { platform: "yt", name: 'Integration Price', function: 'brandPriceYouTubeIntegration' },
    // { platform: "yt", name: 'Preroll Price', function: 'brandPriceYouTubeIntegration' },
    // { platform: "yt", name: 'YouTube Short Price', function: 'brandPriceYouTubeShort' },
    // { platform: "yt", name: 'Video Price', function: 'brandPriceYouTubeVideo' },
    { platform: "all", name: "Tag Relevance", function: "tagRelevance" }
];

const sortOptions = [
    ...sortOptionsBase.reduce((acc, cv) => {
        acc.push({ ...cv, direction: 'desc', value: cv.platform + "-" + cv.function + "-desc" } as SortOption);
        acc.push({ ...cv, direction: 'asc', value: cv.platform + "-" + cv.function + "-asc" } as SortOption);
        return acc;
    }, [] as SortOption[]),
];

function filterSortOptions(opt: any, platform: Platform, campaignType: CampaignType) {
    if (campaignType === "Song" && opt.value.toLocaleLowerCase().includes('brand')) {
        return false;
    }
    if (campaignType !== "Song" && opt.value.toLowerCase().includes('song')) {
        return false;
    }
    return (opt.platform === "tt" && platform === "TikTok") ||
        (opt.platform === "ig" && platform === "Instagram") ||
        (opt.platform === "yt" && platform === "YouTube") ||
        (opt.platform === "all");
}
// END SORT OPTIONS STUFF

interface TalentFilterComponent {
    rosterMode?: boolean, // ???
    // recs?: CreatorRecommendationList,
    // showRecClicks?: number,
    selectedTalent?: SelectedTalent,
    filter: TalFilter,
    updateFilter: (filterPartial: Partial<TalFilter>) => void,
    // handleAdd?: (bidItem: { id: string, bidPrice: number, isRec?: boolean, notes?: string }) => void,
    handleAdd?: (id: string, selectedTalentItem: SelectedTalent["id"]) => void,
    handleRemove?: (id: string) => void,
    talent: Talent[],
    lists?: List[],
    countryList?: string[],
    tagList?: string[],
    cityList?: string[],
}

export default function TalentFilter2(props: TalentFilterComponent) {
    const authedUser = useContext(UserContext);
    const managerMode = authedUser?.roles?.manager || authedUser?.roles?.admin;
    const { selectedTalent, rosterMode, filter, updateFilter, handleAdd, handleRemove, lists = [], talent, countryList = [], tagList = [], cityList = [] } = props;

    // TJN TO-DO: Recs re-implement?
    // setLists(activeLists.filter((list) => {
    //     if (filter.showSongPrice) {
    //         return !list.name?.toLowerCase().includes("brand");
    //     } else {
    //         return !list.name?.toLowerCase().includes("music");
    //     }
    // }));

    const filteredSortOptions = useMemo(() => {
        // console.log("rerunning filteredSortOptions");
        return sortOptions.filter((opt) => filterSortOptions(opt, filter.campaignPlatform, filter.campaignType || "Song"));
    }, [filter.campaignPlatform, filter.campaignType]);

    return (<div>
        <section className="TalentFilterAndList" style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: 'calc(100vh - 10rem)'
        }}>
            <section className="TalentFilterOuter mb-100">
                {!rosterMode ?
                    <div className='flex align-items-center flex-wrap mt-100'>
                        <div className="mr-50">
                            <CurrencySwitch
                                disabled={!!(Object.keys(selectedTalent || {}).length)}
                                locked={filter.lockCurrencyFromBooker || filter.lockCurrencyFromList}
                                checked={filter.currency === 'GBP'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFilter({ currency: e.target.checked ? 'GBP' : 'USD' })}
                            />
                        </div>
                        <div className="mr-50">
                            <div className="label">Platform</div>
                            <div className="my-25">
                                <Tooltip title={filter.lockCampaignPlatform ? `List locked to ${filter.campaignPlatform}.` : !!(Object.keys(selectedTalent || {}).length) ? "Remove selected talent to change platforms" : ''}>
                                    <RadioGroup
                                        name="platform-select"
                                        row
                                        value={filter.campaignPlatform}
                                        onChange={((e) => updateFilter({ campaignPlatform: (e.target as HTMLInputElement).value as Platform }))}
                                    >
                                        <FormControlLabel disabled={filter.lockCampaignPlatform || !!(Object.keys(selectedTalent || {}).length)} value="TikTok" control={<Radio />} label={<img className="true-color-icon" src="/icon-tiktok.svg" alt="TikTok" />} />
                                        <FormControlLabel disabled={filter.lockCampaignPlatform || !!(Object.keys(selectedTalent || {}).length)} value="Instagram" control={<Radio />} label={<img className="true-color-icon" src="/icon-instagram.svg" alt="Instagram" />} />
                                        <FormControlLabel disabled={filter.lockCampaignPlatform || !!(Object.keys(selectedTalent || {}).length)} value="YouTube" control={<Radio />} label={<img className="true-color-icon" src="/icon-youtube.svg" alt="YouTube" />} />
                                    </RadioGroup>
                                </Tooltip>
                            </div>
                        </div>
                        <div>
                            {filter.showCampaignTypeSelect ? (
                                <>
                                    <div className="form-group">
                                        <div className="label">Campaign Type</div>
                                        <select className="mt-25 mb-50" name="campaignType" required value={filter.campaignType}
                                            disabled={filter.lockCampaignType || !!(Object.keys(selectedTalent || {}).length)}
                                            onChange={(event) => updateFilter({ campaignType: event.target.value as CampaignType })}>
                                            <option value="" disabled>Choose Type...</option>
                                            <option value="Song">Song Promos</option>
                                            <option value="Brand">Brand Promos</option>
                                        </select>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                    : null}
                <section className="TalentFilter">
                    <div className="TalentFilterRow">
                        <SearchIcon />
                        <input id="TalentSearch" placeholder="Search by User Name" value={filter.search} onChange={(e) => updateFilter({ search: e.target.value })} />
                    </div>

                    {!rosterMode ? (
                        <>
                            <div className="TalentFilterRow">
                                <ChipRange
                                    label={grailUtil.deepEqual(filter.priceRange, defaultFilter.priceRange) ? 'All Prices' : 'Price: $' + filter.priceRange.lower + '-' + (filter.priceRange.upper === 10000 ? "$10K+" : '$' + filter.priceRange.upper)}
                                    value={[filter.priceRange?.lower, filter.priceRange?.upper]}
                                    onChange={(newFilter) => updateFilter({ "priceRange": newFilter })}
                                    min={defaultFilter.priceRange.lower}
                                    minLabel="$0"
                                    max={defaultFilter.priceRange.upper}
                                    maxLabel="$10K+"
                                    step={250}
                                />

                                <ChipRange
                                    label={grailUtil.deepEqual(filter.ttFollowersRange, defaultFilter.ttFollowersRange) ? 'Any Followers' : 'TikTok Followers: ' + grailUtil.abbreviateNumber(filter.ttFollowersRange.lower) + '-' + (filter.ttFollowersRange.upper === defaultFilter.ttFollowersRange.upper ? "5M+" : grailUtil.abbreviateNumber(filter.ttFollowersRange.upper))}
                                    value={[filter.ttFollowersRange.lower, filter.ttFollowersRange.upper]}
                                    onChange={(newFilter) => updateFilter({ "ttFollowersRange": newFilter })}
                                    min={defaultFilter.ttFollowersRange.lower}
                                    minLabel="0"
                                    max={defaultFilter.ttFollowersRange.upper}
                                    maxLabel="5M+"
                                    step={100000}
                                />

                                <ChipSelect
                                    label={filter.countries?.length ? 'Countries: ' + filter.countries?.length : 'All Countries'}
                                    active={filter.countries?.length ? true : false}
                                    options={countryList || []}
                                    value={filter.countries || []}
                                    onChange={(newFilter) => updateFilter({ "countries": newFilter as string[] })}
                                />

                                <ChipSelect
                                    label={filter.contentTags?.length ? 'Tags: ' + filter.contentTags?.length : 'All Tags'}
                                    active={filter.contentTags?.length ? true : false}
                                    options={tagList || []}
                                    value={filter.contentTags || []}
                                    onChange={(newFilter) => updateFilter({ "contentTags": newFilter as string[] })}
                                />

                                <ChipSelect
                                    label={filter.cities?.length ? 'City: ' + filter.cities?.length : 'All Cities'}
                                    active={filter.cities?.length ? true : false}
                                    options={cityList || []}
                                    value={filter.cities || []}
                                    onChange={(newFilter) => updateFilter({ "cities": newFilter as string[] })}
                                />

                                <ChipSelect
                                    label={filter.sortBy ? (function () {
                                        if (filter.sortBy) {
                                            const sortByOpt = sortOptions.find((opt) => opt.value === filter.sortBy);
                                            return <>
                                                {sortByOpt?.value.includes('tt-') ? <span className="icon-tiktok icon-black icon-16 mr-50"></span> : null}
                                                {sortByOpt?.name}
                                                {sortByOpt?.value.includes('-desc') ? <ArrowDownwardIcon sx={{ padding: '0', fontSize: 18, marginLeft: '0.5rem' }} color="success" /> : null}
                                                {sortByOpt?.value.includes('-asc') ? <ArrowUpwardIcon sx={{ padding: '0', fontSize: 18, marginLeft: '0.5rem' }} color="warning" /> : null}
                                            </>;
                                        } else {
                                            return "Sort By";
                                        }
                                    })() : null}
                                    active={!!(filter.sortBy)}
                                    options={filteredSortOptions.map((opt) => opt.value)}
                                    names={filteredSortOptions.map((opt) =>
                                        <div className="inline-flex">
                                            {opt.value.includes('tt-') ? <span className="icon-tiktok icon-black icon-24 mr-50"></span> : null}
                                            {opt.name}
                                            {opt.value.includes('-desc') ? <ArrowDownwardIcon sx={{ padding: '0', fontSize: 18, marginLeft: '0.5rem' }} color="success" /> : null}
                                            {opt.value.includes('-asc') ? <ArrowUpwardIcon sx={{ padding: '0', fontSize: 18, marginLeft: '0.5rem' }} color="warning" /> : null}
                                        </div>)}
                                    value={filter.sortBy || 'tt-followers-desc'}
                                    onChange={(newFilter) => updateFilter({ "sortBy": newFilter as string })}
                                    singleSelect
                                />

                                <ChipSelect
                                    label={filter.tagFilterLogic === "OR" ? "Match Any Tags" : "Match All Tags"}
                                    active={!!(filter.tagFilterLogic)}
                                    options={["OR", "AND"]}
                                    namesPrefix="Show Creators that match:"
                                    names={["Any Selected Tags", "All Selected Tags"]}
                                    value={filter.tagFilterLogic || "OR"}
                                    onChange={(newFilter) => updateFilter({ "tagFilterLogic": newFilter as string })}
                                    singleSelect
                                />
                            </div>

                            <div className="TalentFilterRow">
                                <ChipRadioGroup
                                    value={filter.listId}
                                    options={(() => {
                                        const list = lists.find((list) => list.id === filter.listId);
                                        const customList = list?.active === false;

                                        if (list && customList) {
                                            return [{ label: list.name, value: list.id }]
                                        } else if (!lists.length) {
                                            return [{ label: "Loading List...", value: "Loading List..." }]
                                        } else {
                                            const typeLists = lists.filter((list) => {
                                                if (filter.campaignType === "Song") {
                                                    return !list.name?.toLowerCase().includes("brand");
                                                } else {
                                                    return !list.name?.toLowerCase().includes("music");
                                                }
                                            });
                                            return [...typeLists.filter((list) => list.active).map((list) => { return { label: list.name, value: list.id } })];
                                        }
                                    })()}

                                    onChange={(newListId) => updateFilter({ "listId": newListId })}
                                />
                            </div>
                        </>
                    ) : null}

                </section>
            </section>

            {talent.length ? (
                <>
                    {filter.campaignPlatform || rosterMode ? (
                        <TalentList talent={filterTalent(filter, talent, lists, managerMode)}
                            isManager={managerMode}
                            add={handleAdd}
                            remove={handleRemove}
                            selectedTalent={selectedTalent}
                            currency={filter.currency}
                            campaignType={filter.campaignType}
                            platform={filter.campaignPlatform}
                            rosterMode={rosterMode}
                            lockTalentCards={filter.lockTalentCards}
                        />
                    ) : <div className="my-100 text-center font-style-italic">Choose a platform to see creators.</div>}
                </>
            ) : <DataLoading />}
        </section>
    </div>);
}

function filterTalent(filter: TalFilter, talent: Talent[], lists: List[], managerMode = false) {
    // if ((recs !== prevRecs && filter.list === "recommended") || filter !== prevFilter || campaignPlatform !== prevCampaignPlatform) {
    // console.log("NEW FILTER!", filter);
    // if (filter.sortBy || filter.search || filter.list || filter.priceRange || filter.ttFollowersRange || filter.contentTags?.length !== defaultFilter.contentTags.length || filter.countries?.length !== 0) {

    const checkPriceMatch = !grailUtil.deepEqual(defaultFilter.priceRange, filter.priceRange);
    const checkFollowerCountMatch = !grailUtil.deepEqual(defaultFilter.ttFollowersRange, filter.ttFollowersRange);

    const newFilteredTalent = talent.filter((tal: Talent) => {
        let platformFilter = true;
        let ttFollowersFilter = true;
        let priceFilter = true;
        let countriesFilter = true;
        let contentTagsFilter = true;
        let citiesFilter = true;
        let searchFilter = true;
        let listFilter = true;


        if (!filter.searchOnly) {
            if (managerMode) {
                platformFilter = true;
            } else if (tal.defaultPrice) {
                platformFilter = true;
            } else {
                platformFilter = false;
            }
        }

        // https://stackoverflow.com/a/3269471/2972273
        if (checkPriceMatch) {
            if ((filter.priceRange.upper === defaultFilter.priceRange.upper ? true : tal.defaultPrice! <= filter.priceRange.upper) && filter.priceRange.lower <= tal.defaultPrice!) {
                priceFilter = true;
            } else {
                priceFilter = false;
            }
        }

        // https://stackoverflow.com/a/3269471/2972273
        if (checkFollowerCountMatch) {
            if ((filter.ttFollowersRange.upper === defaultFilter.ttFollowersRange.upper ? true : tal.ttFollowers! <= filter.ttFollowersRange.upper) && filter.ttFollowersRange.lower <= tal.ttFollowers!) {
                ttFollowersFilter = true;
            } else {
                ttFollowersFilter = false;
            }
        }

        if (filter?.contentTags?.length) {
            if (filter.tagFilterLogic === "AND") {
                contentTagsFilter = filter.contentTags.every(tag => (tal.contentTags || []).includes(tag.toLowerCase()));
            } else {
                contentTagsFilter = filter.contentTags.some((tag: string) => (tal.contentTags || [])!.includes(tag.toLowerCase()));
            }
        }

        if (filter?.cities?.length) {
            citiesFilter = filter.cities.some((city: string) => (tal.cities || []).includes(city));
        }

        if (filter?.countries?.length) {
            countriesFilter = filter.countries.includes(tal.country!);
        }

        if (filter?.search?.length) {
            const cleanSearch = filter.search.replace('@', '').toLocaleLowerCase();

            if (tal.tiktokUser?.toLocaleLowerCase()?.includes(cleanSearch) || tal.instagramUser?.toLocaleLowerCase()?.includes(cleanSearch) || tal.youtubeUser?.toLocaleLowerCase()?.includes(cleanSearch)) {
                searchFilter = true;
            } else if (filter.search === tal.id) {
                searchFilter = true;
            } else {
                searchFilter = false;
            }
        }

        if (filter?.listId?.length) {
            // TJN TO-DO: Recs - re-implement?
            // if (filter?.list === 'recommended' && recs?.find((rec) => rec?.id === tal?.id)) {
            //     return true;
            // } // else 

            if (lists.find((list) => list.id === filter.listId)?.talent?.[tal.id]) {
                // PERM: new format
                listFilter = true;
            }
            // else if (lists.find((list) => list.id === filter.listId)?.talentIds?.includes(tal.id)) {
            //     listFilter = true;
            // }
            else {
                listFilter = false;
            }
        }

        return (platformFilter && priceFilter && ttFollowersFilter && countriesFilter && contentTagsFilter && citiesFilter && searchFilter && listFilter);
    });

    if (filter.searchOnly) {
        // console.log(`search only`);
        return newFilteredTalent;
    } else {
        // console.log(`SHOULD SORT BY`, filter.sortBy);
        const sortedTalent = sortTalentNew(newFilteredTalent, filter.sortBy!, filter.contentTags);
        return sortedTalent;
    }
}

function sortTalentNew(talent: Talent[], sortType: string, contentTags: string[]) {
    const sort = sortOptions.find((opt) => opt.value === sortType);

    if (sort) {
        const sorted = talent.sort((tal1: Talent, tal2: Talent) => {
            // All Prices
            if (sort.value.toLowerCase().includes('price')) {
                if (sort.direction === 'desc') {
                    return (tal2.localPrices?.[sort.function as keyof Prices] || 0) - (tal1.localPrices?.[sort.function as keyof Prices] || 0);
                } else {
                    return (tal1.localPrices?.[sort.function as keyof Prices] || 0) - (tal2.localPrices?.[sort.function as keyof Prices] || 0);
                }
            }
            // All Followers
            else if (sortType.toLowerCase().includes('followers')) {
                const platformFollowersKey = (sort.platform + "Followers") as keyof Talent;
                if (sort.direction === 'desc') {
                    return (tal2[platformFollowersKey] as number || 0) - (tal1[platformFollowersKey] as number || 0);
                } else {
                    return (tal1[platformFollowersKey] as number || 0) - (tal2[platformFollowersKey] as number || 0);
                }
            }
            // TikTok Stats
            else if (sortType === 'tt-views-desc') {
                return (tal2.ttStats?.averageViews || 0) - (tal1.ttStats?.averageViews || 0)
            } else if (sortType === 'tt-views-asc') {
                return (tal1.ttStats?.averageViews || 0) - (tal2.ttStats?.averageViews || 0)
            }
            else if (sortType === 'tt-engagement-desc') {
                return (tal2.ttStats?.engagementRate || 0) - (tal1.ttStats?.engagementRate || 0)
            } else if (sortType === 'tt-engagement-asc') {
                return (tal1.ttStats?.engagementRate || 0) - (tal2.ttStats?.engagementRate || 0)
            }
            // Tag relevance
            else if (sortType === 'all-tagRelevance-desc') {
                const tal1Points = calculateTagRelevanceScore(tal1.contentTags, contentTags);
                const tal2Points = calculateTagRelevanceScore(tal2.contentTags, contentTags);
                return (tal2Points) - (tal1Points);
            } else if (sortType === 'all-tagRelevance-asc') {
                const tal1Points = calculateTagRelevanceScore(tal1.contentTags, contentTags);
                const tal2Points = calculateTagRelevanceScore(tal2.contentTags, contentTags);
                return tal1Points - tal2Points;
            }
            //
            else {
                console.warn('Sort Not Implemeneted!', sortType);
                return 1;
            }
        });

        return sorted;
    } else {
        console.warn("Sort not found", sortType);
        return talent;
    }
}

function calculateTagRelevanceScore(talTags: string[] | undefined, contentTags: string[], debug?: string) {
    const lowerContentTags = contentTags.map((tag) => tag.toLowerCase());

    const talTagsArr = [...talTags || []]; // copy array to manipulate/reverse non-destructively
    talTagsArr.length = 5; // enforce length of 5, in case there are missing tags.
    talTagsArr.reverse();

    let points = 0;

    for (const [idx, tag] of talTagsArr.entries()) {
        if (lowerContentTags.includes((tag || '').toLowerCase())) {
            points += (idx + 1);
        }
    }

    // if (debug && points > 5) {
    //     console.log(`calculateTagRelevanceScore:`, debug, talTagsArr, points);
    // }

    return points;
}